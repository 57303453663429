@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url("./kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2");
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}


html {
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
  scrollbar-width: none;
  font-family: 'Epilogue', sans-serif;
  height: 100%;
  scroll-behavior: smooth;


/* -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; */
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
::-webkit-scrollbar {
  width: 0px;  /* remove scrollbar space */
  background: transparent;  /* optional: just make scrollbar invisible */
}
/* 
@-moz-document url-prefix() {
  html,body{overflow: hidden !important;}
} */

element {overflow-y: scroll;}


body{
  height: 100%;
}


.ant-tabs-tabpane .ant-tabs-tabpane-active{

  display: flex;
  justify-content: center;

}




.new-ant-class{
  overflow: 'auto' !important;
  height : '100vh' !important;
  position:  'fixed' !important;
  left: 0 !important;
}


.ant-menu-title-content{
flex: auto;
min-width: 0;
overflow: hidden;
text-overflow: None !important;
}


/* .regulate__width{
  max-width: 60%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 321) {
} */

